/* @flow */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import ContentSection from 'elements/ContentSection';
import { Query } from 'react-apollo';
// import { EditSpecialtyModal } from './SpecialtyForms';
import TableColumnSort from 'layout/TableColumnSort';
import { Link, Route } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import SectionDropdownField from 'elements/StandardSectionDropdownField';

import type {
  SpecialtyContentProps,
  SpecialtyContentState,
  SpecialtyFeedProps,
  SpecialtyQueryProps,
  SpecialtyTableHeaderRowProps,
  SpecialtyTableRowProps
} from 'specialty/types';
import type {
  connectionQueryType,
  SectionDataType,
  SectionListProps,
  SectionListState,
  SharedSectionCallbacks
} from 'shared/types';
import { Alert } from 'reactstrap';
import SectionSearchField from 'elements/SectionSearchField';
import SectionTable from 'elements/SectionTable';
//import SectionLoadMoreButton from 'elements/SectionLoadMoreButton';
import SectionActionButtons from 'elements/SectionActionButtons';
import SectionError from 'elements/SectionError';
import { specialtyConnectionQuery } from 'specialty/queries';
//import { animateScroll } from 'functions/Functions';
import { EditSpecialtyModal, AddSpecialtyModal, DeleteSpecialtyModal} from './SpecialtyForms';
import {createBrowserHistory} from "history";
import { getPermissions } from 'functions/AuthFunctions';
import qs from "qs";
import ModalSection, { modalClose, modalHide } from 'elements/ModalSection';

const history = createBrowserHistory();
const ModalFormContext = React.createContext();
class SpecialtySection extends React.Component<SectionListProps, SectionListState> {
  waitInterval: number;
  enterKey: number;
  timer: any;
  callbacks: SharedSectionCallbacks;

  constructor(props: SectionListProps) {
    super(props);
    this.state = {
      showModalForm:true,
      filter: '',
      filter2Source:'',
      filter2Certified:10,
      filter2Status:10,
      filter2Clean:10,
      filter2Service:'',
      default: {
        defaultSelectSource:'',
        defaultSelectCertified:'',
        defaultSelectStatus:'',
        defaultSelectclean:'',
        defaultSelectService:''
      },
      afterCurser: '',
      order: {
        sortField: 'SLUG',
        sortDirection: 'ASC'
      },
      limit: 25,
      offset: 0,
      pageNumber: 0
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
    this.reFocus = this.reFocus.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.updateSort = this.updateSort.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.resetFilter = this.resetFilter.bind(this);


    this.waitInterval = 500;
    this.enterKey = 13;

    /**
     * The following object of references allows us to pass our logic to the various
     * lower components that require some of the state data from this top level.
     */
    this.callbacks = {
      handleChange: this.handleChange,
      handleKeyDown: this.handleKeyDown,
      clearFilter: this.clearFilter,
      updateSort: this.updateSort,
      handleFilterChange: this.handleFilterChange,
      resetFilter:this.resetFilter,
      updateFirst: this.updateFirst
    };

    this.pagehandler = {
      handlePage: this.handlePage
    };
  }

  UNSAFE_componentWillMount() {
    this.timer = null;
  }

  updateModalVisibilty = (data) => {
    this.setState({showModalForm:data});
  }

  componentDidMount(){
    const filterParams = history.location.search.substr(1);
    const filtersFromParams = qs.parse(filterParams);
    if(filtersFromParams.search){
     
      this.setState(() => ({
        filter: filtersFromParams.search
      }));
    }
    if(filtersFromParams.page){
     
      this.setState(() => ({
        pageNumber: filtersFromParams.page
      }));
    }
    if(filtersFromParams.curser){
     
      this.setState(() => ({
        afterCurser: filtersFromParams.curser
      }));
    }

    
    
  }

  reFocus = (target: HTMLInputElement) => {
    const elemId = target.getAttribute('id');
    const element = document && elemId && document.getElementById(elemId);
    if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
      element.focus();
    }
  };

  updateSort = (field: string, direction: string) => {
    const sortField = field ? field : this.state.order.sortField;
    const sortDirection = direction ? direction : this.state.order.sortDirection;

    this.setState(() => ({
      order: {
        sortField: sortField,
        sortDirection: sortDirection
      }
    }));
  };

  resetFilter = (id) => {
    if(id == 'source'){
      this.setState(() => ({
        filter2Source:'',
        default: {
          defaultSelectSource:''
        }
      }));
     }
    if(id == 'is_board_certified'){
      this.setState(() => ({
        filter2Certified:10,
        default: {
          defaultSelectCertified:''
        }
      }));
     }
     if(id == 'status'){
      this.setState(() => ({
        filter2Status:10,
        default: {
          defaultSelectStatus:''
        }
      }));
     }
     if(id == 'is_clean'){
      this.setState(() => ({
        filter2Clean:10,
        default: {
          defaultSelectClean:''
        }
      }));
     }
     if(id == 'service_line'){
      this.setState(() => ({
        filter2Service:'',
        
        default: {
          defaultSelectService:''
        }
      }));
    }
  }

  handleFilterChange = (target: HTMLInputElement, reset=false) => {
    
    // const filterField = field ? field : this.state.filter2;
    // const filtername = name ? name : this.state.filter2name;
    if(target.id == 'is_board_certified'){
    this.setState(() => ({
      filter2Certified:target.value,
      default: {
        defaultSelectCertified:target
      }
    }));
   }
   if(target.id == 'status'){
    this.setState(() => ({
      filter2Status:target.value,
     
      default: {
        defaultSelectStatus:target
      }
    }));
   }
   if(target.id == 'source'){
    this.setState(() => ({
      filter2Source:target.value,
      
      default: {
        defaultSelectSource:target
      }
    }));
   }
   if(target.id == 'is_clean'){
    this.setState(() => ({
      filter2Clean:target.value,
      
      default: {
        defaultSelectClean:target
      }
    }));
   }
   if(target.id == 'service_line'){
    this.setState(() => ({
      filter2Service:target.value,
      
      default: {
        defaultSelectService:target
      }
    }));
   }
  
  };

  clearFilter = () => {
    this.setState(() => ({
      filter:'',
      filter2Source:'',
      filter2Certified:10,
      filter2Status:10,
      filter2Clean:10,
      filter2Service:'',
      default: {
        defaultSelectSource:''
      }
    }));

    history.replace(``);
  };

  updateFirst = (n: number) => {
    this.setState(() => ({
      limit: n
    }));
  };

  handleChange = ({ target }: { target: HTMLInputElement }) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(
      function doChange() {
        this.triggerChange(target);
      }.bind(this),
      this.waitInterval
    );
  };

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === this.enterKey) {
      const target = e.target;
      if (target instanceof HTMLInputElement) {
        const attribute: string | null | void = target.getAttribute('id');
        const elemId: string = attribute && typeof attribute === 'string' ? attribute : '';
        const element: HTMLElement | null = document.getElementById(elemId);
        if (typeof element !== 'undefined' && element !== null && element instanceof HTMLInputElement) {
          this.triggerChange(target);
        }
      }
    }
  };

  triggerChange = (target: HTMLInputElement) => {
    this.setState(() => ({
      filter: target.value ? target.value : ''
    }));
    if (target.value) {
      this.reFocus(target);
    }

    history.push(`?search=${target.value}`);
  };

  handlePage = (id,page) => {
     this.setState(() => ({
       afterCurser: id,
       pageNumber:page
     }));

     history.push(`?page=${page}&curser=${id}`);
     //window.location = window.location.pathname +"?page="+ page
     //this.props.propcallbacks.handleChange();
   };

  render() {
    const match = this.props.match;
    const sharedSearchData = {
      filter: this.state.filter,
      order: {
        sortField: this.state.order.sortField,
        sortDirection: this.state.order.sortDirection
      },
      limit: this.state.limit,
      offset: this.state.offset
    };

    const ModalRoutes = () => {
      return (
        <React.Fragment>
          <Route
            exact
            path={`${match.path}/edit/:slug`}
            render={(props: SectionListProps) => (
               this.state.showModalForm && <EditSpecialtyModal
                {...props}
                search={sharedSearchData}
                slug={ props.match.params && typeof props.match.params.slug === 'string' ? props.match.params.slug : ''}
                updateModalVisibilty = {this.updateModalVisibilty}
                showModalForm = {this.state.showModalForm}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/delete/:slug`}
            render={(props: SectionListProps) => (
               this.state.showModalForm && <DeleteSpecialtyModal
                {...props}
                search={sharedSearchData}
                slug={ props.match.params && typeof props.match.params.slug === 'string' ? props.match.params.slug : ''}
                callbacks={this.callbacks}
              />
            )}
          />
           <Route
            exact
            path={`${match.path}/add/`}
            render={(props) => (
              <AddSpecialtyModal
                {...props}
                search=""
                specialty=""
                slug={ props.match.params && typeof props.match.params.slug === 'string' ? props.match.params.slug : ''}
                updateModalVisibilty = {this.updateModalVisibilty}
              />
            )}
          />
        </React.Fragment>
      );
    };

    const buttonLinks: Array<ActionButtonLink> | boolean = getPermissions('emails')
      ? [
        {
          uri: '/specialty/add',
          id: 'addUserLink',
          text: 'Add Specialty',
          icon: 'fas fa-plus'
        }
        ]
      : false;

    // Pass all the things to content wrapper.
    const sectionData: SectionDataType = {
      heading: 'Specialties',
      content: (
        <SpecialtyContent
          callbacks={this.callbacks}
          data={{
            filter: this.state.filter,
            filter2Source: this.state.filter2Source,
            filter2Status: this.state.filter2Status,
            filter2Clean: this.state.filter2Clean,
            filter2Certified: this.state.filter2Certified,
            filter2Service: this.state.filter2Service,
            default: this.state.default,
            order: {
              sortField: this.state.order.sortField,
              sortDirection: this.state.order.sortDirection
            },
            offset: this.state.afterCurser,
            limit: this.state.limit,
            page: this.pagehandler,
            pageNumber: this.state.pageNumber
          }}
          location={this.props.location}
        />
      ),
      actions: <SectionActionButtons links={buttonLinks} />,
      modals: <ModalRoutes />
    };

    return (
      <React.Fragment>
        <ModalFormContext.Provider value={{updateModalVisibilty:this.updateModalVisibilty}} >
          <ContentSection data={sectionData} history={this.props.history} />
        </ModalFormContext.Provider>
      </React.Fragment>
    );
  }
}

/**
 * Component to handle entire table.
 */
class SpecialtyContent extends React.Component<SpecialtyContentProps, SpecialtyContentState> {
  render() {
   
    return (
      <div className="group-landing--wrapper">
        <SectionSearchField
          filter={this.props.data.filter}
          callbacks={this.props.callbacks}
          element={{
            id: 'groupList',
            placeholder: 'Search Specialties...'
          }}
        />
            <div className='filter-btn-div'><button className='btn btn-secondary' onClick={this.props.callbacks.clearFilter}>Reset Filter</button></div>
         
        <div className="table-responsive">
          <SpecialtyQuery data={this.props.data} callbacks={this.props.callbacks} location={this.props.location} />
        </div>
      </div>
    );
  }
}

/**
 * Default class to handle returning the formatted list of results.
 */
class SpecialtyQuery extends React.Component<SpecialtyQueryProps> {
  render() {
    // @todo: This may need to have the offset & limit sent back to the parent state.
    // This is the filter sent from the parent form(s).
    const FILTER = this.props.data.filter ? this.props.data.filter : '';
    const FILTER2STATUS = this.props.data.filter2Status ? this.props.data.filter2Status : '';
    const FILTER2SOURCE = this.props.data.filter2Source ? this.props.data.filter2Source : '';
    const FILTER2CLEAN = this.props.data.filter2Clean ? this.props.data.filter2Clean : '';
    const FILTER2CERTIFIED = this.props.data.filter2Certified ? this.props.data.filter2Certified : '';
    const FILTER2SERVICE = this.props.data.filter2Service ? this.props.data.filter2Service : '';
    // Default offset.
    const OFFSET = this.props.data.offset ? this.props.data.offset : 0;
    // Default item to sort on.
    const ORDER = this.props.data.order
      ? {
          sort: this.props.data.order.sortField,
          direction: this.props.data.order.sortDirection
        }
      : {
          sort: 'NAME',
          direction: 'ASC'
        };
    // Default items per page to display.
    const LIMIT = this.props.data.limit ? this.props.data.limit : 25;

    return (
      <Query
        query={specialtyConnectionQuery}
        pollInterval={0}
        notifyOnNetworkStatusChange
        variables={{
          filter: FILTER,
          filterSource: FILTER2SOURCE,
          filterStatus: FILTER2STATUS,
          filterClean: FILTER2CLEAN,
          filterCertified: FILTER2CERTIFIED,
          filterService:FILTER2SERVICE,
          afterCurser: OFFSET,
          first: LIMIT,
          orderBy: ORDER
        }}
        fetchPolicy="no-cache"
      >
        {({ variables, loading, data, fetchMore, networkStatus }) => {
          // Skip loading icon/flash when we are polling for new results.
          if (loading && networkStatus !== 6 && networkStatus !== 3) {
            // Handle loading screen.
            const row = {
              classes: 'loading',
              content: <i className="fal fa-spinner" />
            };
            return (
              <SectionTable
                render={() => (
                  <tbody>
                    <tr>
                      <td className={row.classes}>{row.content}</td>
                    </tr>
                  </tbody>
                )}
              />
            );
          }

          const query: [] = data && data.specialties ? data.specialties : [];

          if (query && query.length === 0 && !loading) {
            // Handle a zero result set.
            const errorContent = (
              <div className="text-center">
                <h4 className="text-warning">No Results Found.</h4>
                <h2 className="text-warning">¯\_(ツ)_/¯</h2>
              </div>
            );
            return <SectionError color="warning" classes="" content={errorContent} />;
          }

          if (!data && !loading) {
            // We've encountered a error connecting to GraphQL server.
            // We should on this account, disable the Create Specialty button.
            // @todo: Should be an easier way to detect an epic failure to connect.
            const button = document.getElementById('addSpecialtyLink');
            if (typeof button !== 'undefined' && button !== null && button instanceof HTMLButtonElement) {
              button.classList.add('disabled');
              button.disabled = true;
            }
            const search = document.getElementById('groupList');
            if (typeof search !== 'undefined' && search !== null && search instanceof HTMLInputElement) {
              search.disabled = true;
            }
            return (
              <Alert color="danger" className={`error--graphql-connection`}>
                <div className={`text-center`}>
                  <h4 className="text-error">GraphQL connection failure.</h4>
                  <h2 className="text-error">¯\_(ツ)_/¯</h2>
                </div>
              </Alert>
            );
          }

          // Pagination issues.
          // @see https://github.com/apollographql/apollo-client/issues/2499
          return (
            <React.Fragment>
              <SpecialtyFeed
                specialties={query.edges || []}
                callbacks={this.props.callbacks}
                pages={query.aggregate}
                data={{
                  total: query && query.length >= 1 ? query.length : 0,
                  filter: FILTER,
                  filter: FILTER,
                  filter2Source: FILTER2SOURCE,
                  filter2Status: FILTER2STATUS,
                  filter2Certified:FILTER2CERTIFIED,
                  filter2Clean:FILTER2CLEAN,
                  filter2Service:FILTER2SERVICE,
                  default: this.props.data.default,
                  order: {
                    sortField: ORDER.sort,
                    sortDirection: ORDER.direction
                  },
                  offset: OFFSET,
                  limit: LIMIT,
                  page:this.props.data.page,
                  pageNumber:this.props.data.pageNumber
                }}
              />
              <Alert color="info" className={`my-0 ${query.length >= 100 ? 'd-block' : 'd-none'}`}>
                <div className="alert--with-icon">
                  <i className="alert-icon fal fa-info-circle" />
                  <div>
                    <span>
                      Not all specialties can be listed at once. Only the first 100 results are shown. Please use the
                      search form to narrow down the results.
                    </span>
                  </div>
                </div>
              </Alert>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

class SpecialtyFeed extends React.Component<SpecialtyFeedProps> {
  handlePageClick = (data) => {
    let page = data.selected;
   
  
    let last = 25 * page;
    let total = last - 1;
    
    this.props.data.page.handlePage(total,page);
    // Friendly method to display the text "Showing X-Y of Z total results.
   
    //window.location = window.location.pathname +"?page="+ currentPage
  };
  render() {
    const data = this.props.data;
    let fromItems = 1;
    let toItems = 25;

    if (this.props.pages.count < 25) {
      fromItems = 1;
      toItems = this.props.pages.count
    }

    else if (this.props.data.pageNumber != 0) {
      fromItems = (this.props.data.pageNumber * 25);
      toItems = (this.props.data.pageNumber * 25) + 24
    }
    if (this.props.specialties ) {
      const total = Math.ceil(this.props.pages.count/this.props.data.limit);
      // Friendly method to display the text "Showing X-Y of Z total results.
      // const fromItems = 1;
      // const toItems = this.props.specialties.length;
      const filterData = {
        filter: this.props.data.filter,
        filter2Source: this.props.data.filter2Source,
        filter2Status: this.props.data.filter2Status,
        filter2Certified: this.props.data.filter2Certified,
        filter2Clean: this.props.data.filter2Clean,
        filter2Service: this.props.data.filter2Service,
        
        order: this.props.data.order,
        limit: this.props.data.limit,
        offset: this.props.data.offset
      };

      // Compile all the table rows together.
      const FeedRows = () => {
        return this.props.specialties.map((group, id) => (
          
          <SpecialtyTableRow row={group.node} key={id} search={filterData} />
        ));


      };
      let sourceOptions = [];
      let oldSourceOptions = [];
      let certifiedOptions = [{value:1,label:"Yes",id:'is_board_certified'},{value: 2,label:"No",id:'is_board_certified'}];
      let activeOptions = [{value:1,label:"Yes",id:'status'},{value:2,label:"No",id:'status'}];
      let cleanOptions = [{value:1,label:"Yes",id:'is_clean'},{value:2,label:"No",id:'is_clean'}];
      let serviceOptions = [];
      let oldServiceOptions = [];
   
       for(let i=0; i < this.props.specialties.length; i++){

         if(!oldSourceOptions.includes(this.props.specialties[i].node.source)){
           sourceOptions.push({ value: this.props.specialties[i].node.source, label: this.props.specialties[i].node.source,id:'source' })
           oldSourceOptions.push(this.props.specialties[i].node.source)
         }

         
          for(let y=0; y < this.props.specialties[i].node.service_lines.length; y++){
          if(!oldServiceOptions.includes(this.props.specialties[i].node.service_lines[y])){
          serviceOptions.push({ value: this.props.specialties[i].node.service_lines[y].name, label: this.props.specialties[i].node.service_lines[y].name,id:'service_line' })
          oldServiceOptions.push( this.props.specialties[i].node.service_lines[y]);
          }
         }

         
         
      }
      let newserviceOptions = serviceOptions.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.value === value.value // Compare by 'id'
        ))
    );
      const selectedSourceObj = sourceOptions.find(item => item.value === this.props.data.filter2Source);
      const selectedCertifiedObj = certifiedOptions.find(item => item.value === this.props.data.filter2Certified);
      const selectedStatusObj = activeOptions.find(item => item.value === this.props.data.filter2Status);
      const selectedCleanObj = cleanOptions.find(item => item.value === this.props.data.filter2Clean);
      const selectedServiceLineObj = newserviceOptions.find(item => item.value === this.props.data.filter2Service);
      
      return (
        <React.Fragment>
           
          <div className="row pb-3 m-auto">
            <div className="col-sm ">
            <label for="source">Source</label>
           <SectionDropdownField
                            id='source'
                            placeholder='Select'
                            default= {this.props.data.filter2Source != ''?(selectedSourceObj):this.props.data.default.defaultSelectSource}
                            //filter={this.props.data.filter}
                            onChange={this.props.callbacks.handleFilterChange}
                            element={{
                              id: 'roleList',
                              
                            }}
                            options= {
                              sourceOptions
                            }
            />
             </div>
             <div className='mt-36'>
            { (this.props.data.filter2Source !== '' &&
             <button className='btn btn-reset' onClick={()=>this.props.callbacks.resetFilter('source')} ><i className="fas fa-times" /></button>
            )}</div>
            
             <div className="col-sm">
             <label for="source">Is Board Certified</label>
             <SectionDropdownField
                            id='is_board_certified'
                            placeholder= 'Select'
                            default= {this.props.data.filter2Certified != ''?(selectedCertifiedObj):this.props.data.default.defaultSelectCertified}
                            //filter={this.props.data.filter}
                            onChange={this.props.callbacks.handleFilterChange}
                            element={{
                              id: 'roleList',
                              
                            }}
                            options= {
                              certifiedOptions
                            }
                            
                          
            />
            </div>
            <div className='mt-36'>
            { (this.props.data.filter2Certified !== 10 &&
             <button className='btn btn-reset' onClick={()=>this.props.callbacks.resetFilter('is_board_certified')} ><i className="fas fa-times" /></button>
            )}</div>

            <div className="col-sm">
            <label for="source">Is Active</label>
                <SectionDropdownField
                            id='active'
                            placeholder= 'Select'
                            default= {this.props.data.filter2Status != ''?(selectedStatusObj):this.props.data.default.defaultSelectStatus}
                            //filter={this.props.data.filter}
                            onChange={this.props.callbacks.handleFilterChange}
                            element={{
                              id: 'roleList',
                              
                            }}
                            options= {
                              activeOptions
                            }
                            
                          
            />
            </div>
            <div className='mt-36'>
            { (this.props.data.filter2Status !== 10 &&
             <button className='btn btn-reset' onClick={()=>this.props.callbacks.resetFilter('status')} ><i className="fas fa-times" /></button>
                          )}</div>

            <div className="col-sm">
            <label for="source">Is Clean</label>
                     <SectionDropdownField
                            id='is_clean'
                            placeholder= 'Select'
                            default= {this.props.data.filter2Clean != ''?(selectedCleanObj):this.props.data.default.defaultSelectClean}
                            //filter={this.props.data.filter}
                            onChange={this.props.callbacks.handleFilterChange}
                            element={{
                              id: 'roleList',
                             
                            }}
                            options= {
                              cleanOptions
                            }
                            
                          
            />
            </div>
            <div className='mt-36'>
            { (this.props.data.filter2Clean !== 10 &&
             <button className='btn btn-reset' onClick={()=>this.props.callbacks.resetFilter('is_clean')} ><i className="fas fa-times" /></button>
                           )}</div>

            <div className="col-sm">
            <label for="source">Service Line</label>
                         <SectionDropdownField
                            id='service_line'
                            placeholder= 'Select'
                            default= {this.props.data.filter2Service != ''?(selectedServiceLineObj):this.props.data.default.defaultSelectService}
                            //filter={this.props.data.filter}
                            onChange={this.props.callbacks.handleFilterChange}
                            element={{
                              id: 'roleList',
                              
                            }}
                            options= {
                              newserviceOptions
                            }
                            
                          
            />
            </div>
            <div className='mt-36'>
              { (this.props.data.filter2Service !== '') &&
             <button className='btn btn-reset' onClick={()=>this.props.callbacks.resetFilter('service_line')} ><i className="fas fa-times" /></button>
              }</div>
          </div>
          { (this.props.pages.count >0 && <h4>
            Showing{' '}
            <strong>
              {fromItems} - {toItems}
            </strong>{' '}
            of <strong>{this.props.pages.count}</strong> rows.
          </h4>)
          }
          <SectionTable
            render={() => (
              <React.Fragment>
                <thead className="thead-light">
                  <SpecialtyTableHeaderRow order={this.props.data.order} callbacks={this.props.callbacks} />
                </thead>
                <tbody className="result-set">
                  <FeedRows />
                  {(this.props.pages.count <= 0 && (
                    <tr><td colspan="8" className=''><strong>No records found</strong></td></tr>
                  ))}
                </tbody>
              </React.Fragment>
            )}
          />
            { (this.props.pages.count >0 && <ReactPaginate
       previousLabel={"previous"}
       nextLabel={"next"}
       breakLabel={"..."}
       breakClassName={"break-me"}
       pageCount={total}
       forcePage={this.props.data.pageNumber}
       // marginPagesDisplayed={2}
       //pageRangeDisplayed={5}
       //callbacks={this.props.callbacks}
       onPageChange={this.handlePageClick}
       containerClassName={"pagination"}
       subContainerClassName={"pages pagination"}
       activeClassName={"active"}
     />
            )}
           
        </React.Fragment>
      );
    }

    // Last case error that something went terribly wrong.
    const errorContent = (
      <div>
        <h4 className="text-danger">Unknown error occurred.</h4>
        <h2 className="text-danger">¯\_(ツ)_/¯</h2>
      </div>
    );
    return <SectionError color="danger" classes="" content={errorContent} />;
  }
}

/**
 * Component to handle single table row of results.
 */

class SpecialtyTableRow extends React.Component<SpecialtyTableRowProps> {

  render() {
    const specialty = this.props.row;
    const cftCount = specialty.cftTerms && typeof specialty.cftTerms === 'object' ? specialty.cftTerms.length : 0;
    const status = specialty.status ? (
      <span className="text-success">enabled</span>
    ) : (
      <span className="text-warning">disabled</span>
    );

    return (
      <ModalFormContext.Consumer>
        {(context) => 
        {
          return (
            <tr id={specialty.id} className={`result-set--row`}>
            <th className="main-cell term" scope="row">
              <Link to={`/specialty/edit/${specialty.slug}`} title={`Edit ${specialty.name}`} onClick={()=>this.handleCopyButtonClick(context.updateModalVisibilty(true))}  >
                {specialty.name}
              </Link>
            </th>
            <td className="d-none d-xl-table-cell text-center">{specialty.is_board_certified == 1 ? 'Yes' : 'No'}</td>
            <td className="d-none d-xl-table-cell text-center">{specialty.status == 1 ? 'Yes' : 'No'}</td>
            <td className="d-none d-xl-table-cell text-center">{cftCount}</td>
           
              {/* <td className="d-none d-xl-table-cell text-center"> {specialty.service_lines.map((service_line, id) => (id != 0 ?',' + service_line.name  : service_line.name))}</td> */}
          
            <td className="d-none d-xl-table-cell text-center">{specialty.source}</td>
            
            <td className="d-none d-xl-table-cell text-center">{specialty.is_clean == 1 ? 'Yes' : 'No'}</td>
            <td className="d-none d-xl-table-cell text-center">{specialty.is_clean_timestamp != 0 ?  new Date(specialty.is_clean_timestamp * 1000).toDateString():'N/A' }</td>
            <td className="actions">
              <Link to={`/specialty/edit/${specialty.slug}`} title={`Edit ${specialty.name}`} className="btn btn-edit">
                <i className="fas fa-edit" />
              </Link>
            </td>
            <td className="actions">
              <Link to={`/specialty/delete/${specialty.slug}`} title={`Delete ${specialty.name}`} className="btn btn-delete">
                <i className="fas fa-times" />
              </Link>
            </td>
          </tr>
          );
        }
      }
       </ModalFormContext.Consumer>
    
    );
  }
}

/**
 * Component to handle table header for CFT results.
 */
class SpecialtyTableHeaderRow extends React.Component<SpecialtyTableHeaderRowProps> {
  render() {
    const name_sort = {
      sortField: 'SLUG',
      default: 'ASC'
    };

    const board_sort = {
      sortField: 'BOARD',
      default: 'ASC'
    };
    const active_sort = {
      sortField: 'ACTIVE',
      default: 'ASC'
    };
    const source_sort = {
      sortField: 'SOURCE',
      default: 'ASC'
    };
    const is_clean_sort = {
      sortField: 'IS_CLEAN',
      default: 'ASC'
    };

    return (
      <tr>
        <th scope="col">
          <div className="">
            <span className="col-label">Name</span>
            <TableColumnSort callbacks={this.props.callbacks} order={this.props.order} sort={name_sort} />
          </div>
        </th>
        <th className="actions" scope="col">
          <span className="col-label">Is Board Certified</span>
          <TableColumnSort callbacks={this.props.callbacks} order={this.props.order} sort={board_sort} />
        </th>
        <th className="actions" scope="col">
          <span className="col-label">Is Active</span>
          <TableColumnSort callbacks={this.props.callbacks} order={this.props.order} sort={active_sort} />
        </th>
        <th scope="col" className="d-none d-xl-table-cell">
          <div className="sorting-cell">
            <span className="col-label"># CFTs</span>
          </div>
        </th>
        {/* <th className="actions" scope="col">
          <span className="col-label">Service Line</span>
        </th> */}
   
        <th className="actions" scope="col">
          <span className="col-label">Source</span>
          <TableColumnSort callbacks={this.props.callbacks} order={this.props.order} sort={source_sort} />
        </th>
      
        <th className="actions" scope="col">
          <span className="col-label">Is Clean</span>
          <TableColumnSort callbacks={this.props.callbacks} order={this.props.order} sort={is_clean_sort} />
        </th>
        <th className="actions" scope="col">
          <span className="col-label">Is Clean Timestamp</span>
        </th>
        <th className="actions" scope="col" colSpan={2}>
          <span className="col-label">Actions</span>
        </th>
      </tr>
    );
  }
}

/**
 * Return the SpecialtySection component as the default.
 */
export default SpecialtySection;
